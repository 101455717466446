@keyframes background {
  0% {
    background-color: black; }
  100% {
    background-color: auto; } }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes tether {
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes grow {
  0% {
    height: 0; }
  100% {
    height: 100%; } }

@keyframes bubbles {
  0% {
    opacity: 0;
    transform: translateY(20%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes loading {
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0%, 25% {
    transform: rotate(0); }
  50%, 75% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

.welcome .logged-in {
  display: block; }

.logged-in, .welcome .not-logged-in, .message #header {
  display: none; }

.hidden {
  display: none !important; }

#prototype {
  grid-column: span 3;
  align-self: start;
  padding: 1rem;
  position: relative;
  z-index: 1;
  background-color: black; }
  #prototype select {
    color: inherit;
    background: none #333; }

#features {
  list-style: none;
  text-align: left;
  margin-top: 10rem;
  padding: 1rem; }
  #features dt {
    font-size: 1rem;
    margin: 1em auto; }
  #features dd {
    margin: auto;
    display: inline; }
  #features dd:before {
    content: ', '; }
  #features dt + dd:before {
    content: none; }
  #features .true {
    color: #19FF8C; }
  #features .false {
    color: #FFD919; }
